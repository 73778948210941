import * as React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import Header from "../components/header"
import FixedFooter from "../components/footer"
import HistoryImg from "../images/Newton2-smaller-1000x622-270w.jpg"

// styles
const headingStyles = {
    marginTop: 140,
    marginBottom: 100,
}

// markup
const HistoryPage = () => {
  return (
    <main>
      <title>Fieldstone Farm History</title>  
      <Header></Header>
      <Container style={headingStyles}>
        <Row>
            <div className="col-sm-8">
            <h2>History Rediscovered</h2>
            <p>
                The original portion of this inn dates to 1770 and was built and owned by Mahlon Hough. Well cared for, it has since only had six families 
                own it in its nearly 250 years and now stands as one of Hillsboro’s largest and finest properties. 
            </p>
            <p>
                In the 1830s, the farmhouse was purchased by miller Elisha Janney, a Quaker who built a great stone grist mill across the street—a 
                four-story structure that dominated the west end of Hillsboro for over 100 years. One of Elisha's three sons was John W. Janney, noted 
                Virginia Legislator and President of the Virginia Convention that decided Secession in 1861. 
            </p>
            <p>
                Major renovations have included removal of the stucco from the exterior, restoration of the 18" plank heart pine flooring, and the addition 
                of a two-story kitchen and Great Room to the farm's east end. 
            </p>
            <p>
                Unique features include a camelback railing on the front hall staircase, original fireplaces, and 2' deep walls and windowsills. This country 
                retreat is beautifully restored and filled with antiques by its present owner/innkeeper.
            </p>
            </div>
            <div className="col-sm-4">
                <Row className="align-items-center">
                    <Col className="d-flex justify-content-center">
                    <img src={HistoryImg} className="d-inline-block align-top" alt="Fieldstone Farm Today"/>
                    </Col>
                </Row>                
                <Row className="align-items-center">
                    <Col className="d-flex justify-content-center">
                    Hillsboro's historic Fieldstone Farm today.
                    </Col>
                </Row>
            </div>            
        </Row>        
      </Container>
      <FixedFooter/>
    </main>
  )
}

export default HistoryPage